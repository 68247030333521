var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "px-2" },
    [
      _c("b-col", { attrs: { cols: "12" } }, [
        _c("h4", { staticClass: "primary fw-bold" }, [
          _vm._v(_vm._s(_vm.title))
        ])
      ]),
      _c(
        "b-col",
        { staticClass: "py-3", attrs: { cols: "12" } },
        [
          _c(
            "b-form-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Tipo de identificación" } },
                    [
                      _c("b-form-select", {
                        attrs: {
                          options: _vm.$store.getters.listaTiposIdentificacion,
                          state: _vm.$v.formR.tipoIdentificacion.$dirty
                            ? !_vm.$v.formR.tipoIdentificacion.$error
                            : null
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "first",
                            fn: function() {
                              return [
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: null } },
                                  [_vm._v("Seleccione")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.$v.formR.tipoIdentificacion.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.formR.tipoIdentificacion,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.formR.tipoIdentificacion.$model"
                        }
                      }),
                      _c("b-form-invalid-feedback", [
                        _vm._v(
                          _vm._s(
                            _vm.validarCampo(_vm.$v.formR.tipoIdentificacion)
                          )
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.textoIdentificacion } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          state: _vm.$v.formR.cedula.$dirty
                            ? !_vm.$v.formR.cedula.$error
                            : null
                        },
                        model: {
                          value: _vm.$v.formR.cedula.$model,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.formR.cedula, "$model", $$v)
                          },
                          expression: "$v.formR.cedula.$model"
                        }
                      }),
                      _c("b-form-invalid-feedback", [
                        _vm._v(_vm._s(_vm.validarCampo(_vm.$v.formR.cedula)))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "pb-4", attrs: { cols: "12" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "pb-3",
                  attrs: { cols: "12", md: "6", "offset-md": "6" }
                },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "primary",
                      model: {
                        value: _vm.readTerminos,
                        callback: function($$v) {
                          _vm.readTerminos = $$v
                        },
                        expression: "readTerminos"
                      }
                    },
                    [
                      _vm._v(" Acepto términos y condiciones "),
                      _c(
                        "a",
                        {
                          staticClass: "danger",
                          attrs: {
                            target: "_blank",
                            href: "static/Terminos_y_condiciones.pdf"
                          }
                        },
                        [_vm._v("Ver términos")]
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6", "offset-md": "6" } },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "primary",
                      model: {
                        value: _vm.readPoliticas,
                        callback: function($$v) {
                          _vm.readPoliticas = $$v
                        },
                        expression: "readPoliticas"
                      }
                    },
                    [
                      _vm._v(" Acepto política de tratamiento de datos "),
                      _c(
                        "a",
                        {
                          staticClass: "danger",
                          attrs: {
                            target: "_blank",
                            href: "static/Tratamiento_de_datos.pdf"
                          }
                        },
                        [_vm._v("Ver políticas")]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        {
          staticClass: "d-flex justify-content-between",
          attrs: { cols: "12" }
        },
        [
          _c(
            "b-button",
            {
              staticClass: "mr-3",
              attrs: { variant: "danger" },
              on: { click: _vm.retornarPagina }
            },
            [_c("i", { staticClass: "las la-reply" }), _vm._v(" Regresar ")]
          ),
          _c(
            "b-button",
            {
              attrs: { variant: "danger", disabled: !_vm.validarForm },
              on: { click: _vm.validarUsuario }
            },
            [
              _c("i", { staticClass: "las la-check-circle" }),
              _vm._v(" Aceptar ")
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }